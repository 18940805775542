export type ProjectType = {
  id: number
  title: string
  period: string
  general: string[]
}

export const projects: ProjectType[] = [
  {
    id: 1,
    title: 'Insurtech Project',
    period: 'ongoing',
    general: [
      'Insurance Claim Assessment using Langchain and GPT',
      'Document Text Extraction & Assessment via AWS Textract and Claude',
      'AI Agent Development & Orchestration using AWS Bedrock',
      'AI Agent Development & Orchestration using Langflow',
      'Prompt Engineering using GPT, Llama and Claude',
      'Integration of Gen AI capabilities into customers architecture landscape',
    ],
  },
]
