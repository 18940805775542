import React from 'react'
import { ProjectType } from '../data/projects'

type Props = {
  project: ProjectType
}

const Project: React.FC<Props> = ({ project }) => {
  return (
    <div className='mt-2'>
      <h3 className='underline'>{project.title}</h3>
      <div className='mb-1'>{project.period}</div>
      <b>General:</b>
      <ul className='list-disc pl-5'>
        {project.general.map(item => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </div>
  )
}

export default Project
