// App.tsx
import React, { useEffect } from 'react'
import CookieConsent from 'react-cookie-consent'
import ReactGA from 'react-ga4'
import Contact from './components/Contact'
import CurrentProject from './components/CurrentProject'
import Disclosure from './components/Disclosure'
import Footer from './components/Footer'
import Header from './components/Header'
import Intro from './components/Intro'
import Work from './components/Work'
import useDevice from './hooks/useDevice'

const GA_MEASUREMENT_ID = 'G-51631121'

const App: React.FC = () => {
  const { isMobileView } = useDevice()

  useEffect(() => {
    ReactGA.initialize(GA_MEASUREMENT_ID)
    ReactGA.send('visit website')
  }, [])

  return (
    <div className='bg-fixed-page overflow-auto'>
      {isMobileView ? (
        <div className='w-full bg-[#00000035] pt-52 pb-10 flex flex-col items-center'>
          <Header />
          <Intro />
          <CurrentProject />
          <Work />
          <Disclosure />
          <Contact />
          <Footer />
        </div>
      ) : (
        <div className='w-full bg-[#00000035] pt-52 pb-10 flex flex-col items-center'>
          <div className='max-w-[1120px]'>
            <Header />
            <Intro />
            <CurrentProject />
            <Work />
            <Disclosure />
            <Contact />
            <Footer />
          </div>
        </div>
      )}

      <CookieConsent
        location='bottom'
        style={{ background: '#A9A9A9' }}
        buttonStyle={{
          background: 'green',
          color: 'white',
          fontSize: '13px',
        }}
        declineButtonStyle={{
          background: 'red',
          color: 'white',
          fontSize: '13px',
          border: '5',
        }}
        enableDeclineButton
        buttonText='Accept'
        declineButtonText='Decline'
        cookieName='gatsby-gdpr-google-analytics'
      >
        This site uses cookies from Google to deliver its services and to analyze traffic.
        Information about your use of this site is shared with Google. By using this site, you agree
        to its use of cookies
      </CookieConsent>
    </div>
  )
}

export default App
